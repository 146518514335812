import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import "./style.css";

const FinalMessage = () => {
    return (
        <div className="final-message">
        <h1>Thank you for visiting our memory page!</h1>
        <p>We hope you enjoyed the journey through our most cherished moments together.</p>
        <img src="../images/kate.jpg" alt="Couple" className="cover-photo" />
        <Link to="/kasia">
            <button>Back to Home</button>
        </Link>
        </div>
    );
};


export default FinalMessage;