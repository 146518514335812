import React, { useState } from 'react';

function AddScoreForm({ addScore, discipline, athletes }) {
  const [participant, setParticipant] = useState('');
  const [score, setScore] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    addScore({ discipline, participants: [participant], score });
    setParticipant('');
    setScore('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Ajouter un score pour {discipline}</h2>
      <select value={participant} onChange={(e) => setParticipant(e.target.value)}>
        <option value="">Choisir un participant</option>
        {athletes.map((athlete) => (
          <option key={athlete} value={athlete}>{athlete}</option>
        ))}
      </select>
      <input
        type="number"
        placeholder="Score"
        value={score}
        onChange={(e) => setScore(e.target.value)}
      />
      <button type="submit">Ajouter Score</button>
    </form>
  );
}

export default AddScoreForm;
