import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import './style.css'

const HomePage = () => {
  useEffect(() => {
    document.title = "Katarzina";  // Set the title
  }, []);

  return (
    <div className="home-page">
      <h1>Welcome to Our Memory Page</h1>
      <p>A journey through our most cherished moments together.</p>
      <img src="../images/kate.jpg" alt="Couple" className="cover-photo" />
      <Link to="/kasia/gallery">
        <button>Discover Our Memories</button>
      </Link>
    </div>
  );
};

export default HomePage;
