import React, { useState } from 'react';

function AddScoreFormPingpong({ addScore, athletes }) {
  const [player1, setPlayer1] = useState('');
  const [player2, setPlayer2] = useState('');
  const [score, setScore] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    addScore({ discipline: 'Pingpong', participants: [player1, player2], score });
    setPlayer1('');
    setPlayer2('');
    setScore('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Ajouter un score pour Pingpong</h2>
      <select value={player1} onChange={(e) => setPlayer1(e.target.value)}>
        <option value="">Choisir le joueur 1</option>
        {athletes.map((athlete) => (
          <option key={athlete} value={athlete}>{athlete}</option>
        ))}
      </select>
      <select value={player2} onChange={(e) => setPlayer2(e.target.value)}>
        <option value="">Choisir le joueur 2</option>
        {athletes.map((athlete) => (
          <option key={athlete} value={athlete}>{athlete}</option>
        ))}
      </select>
      <input
        type="text"
        placeholder="Score"
        value={score}
        onChange={(e) => setScore(e.target.value)}
      />
      <button type="submit">Ajouter Score</button>
    </form>
  );
}

export default AddScoreFormPingpong;
