import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from '../Header';
import DisciplineMenu from '../DisciplineMenu';
import DisciplinePage from '../DisciplinePage';
import AddScoreForm from '../AddScoreForm';
import AddScoreFormPingpong from '../AddScoreFormPingpong';
import History from '../History';
import DisciplineDescription from '../DisciplineDescription';
import './style.css';

const disciplines = ["Natation", "Badminton", "Pingpong", "Vélo", "Course à pied"];
const athletes = ["Timothée", "Jérôme", "Vianney", "Louis", "Grégoire"];

function App() {
  const [scores, setScores] = useState([]);
  const [history, setHistory] = useState([]);

  const addScore = (newScore) => {
    setScores([...scores, newScore]);
    setHistory([...history, newScore]);
  };

  return (
      <div className="App">
        <Header />
        <DisciplineMenu disciplines={disciplines} />
        <Routes>
          <Route path="/" element={<DisciplineDescription />} />
          {disciplines.map((discipline) => (
            <Route
              key={discipline}
              path={`/${discipline.toLowerCase()}`}
              element={<DisciplinePage scores={scores.filter(score => score.discipline === discipline)} discipline={discipline} />}
            />
          ))}
        </Routes>
        {disciplines.map((discipline) =>
          discipline === "Pingpong" ? (
            <AddScoreFormPingpong addScore={addScore} athletes={athletes} key={discipline} />
          ) : (
            <AddScoreForm addScore={addScore} discipline={discipline} athletes={athletes} key={discipline} />
          )
        )}
        <History history={history} />
      </div>
  );
}

export default App;
