import React from 'react';

function DisciplineDescription() {
  return (
    <div>
      <h2>Descriptions des Disciplines</h2>
      <p><strong>Natation:</strong> Compétition de nage, comprenant plusieurs styles comme le crawl, la brasse, le papillon et le dos crawlé.</p>
      <p><strong>Badminton:</strong> Jeu de raquette joué avec un volant. Les points sont marqués en frappant le volant pour qu'il atterrisse dans la moitié de terrain adverse. Match en 21 points.</p>
      <p><strong>Pingpong:</strong> Jeu de raquette sur une table, où les joueurs frappent une balle de pingpong par-dessus un filet. Match en 11 points.</p>
      <p><strong>Vélo:</strong> Course de cyclisme, qui peut être sur route ou sur piste. 20km</p>
      <p><strong>Course à pied:</strong> Compétition de course sur différentes distances. 5km</p>
    </div>
  );
}

export default DisciplineDescription;
