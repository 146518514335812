import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FrWelcome from './Composents/FrComponents/Welcome/Welcome';
import EnWelcome from './Composents/EnComponents/Welcome/Welcome';
import ErrorPage from './Composents/EnComponents/ErrorPage/ErrorPage';
import Max from './Composents/Max';
import EnContact from './Composents/EnComponents/Contact';
import Portfolio from './Composents/EnComponents/Portfolio';
import Hack from './Composents/Hack';
import Useless from './Composents/Useless';
import MainNote from './NoteKeeper/MainNote';
import OG from './Composents/olympicsGame/App/';
import Kasia from './Composents/Kasia/App/HomePage';
import Gallery from './Composents/Kasia/Gallery';
import FinalMessage from './Composents/Kasia/FinalMessage';
// import EnFooter from './Composents/EnComponents/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  // const [language, setLanguage] = useState('en'); // Définition de l'état de la langue

  // const toggleLanguage = () => {
  //   // Fonction pour basculer entre les langues
  //   setLanguage(language === 'en' ? 'fr' : 'en');
  // };

  // // Utilisation du hook useNavigate pour gérer les redirections
  // const navigate = useNavigate();

  return (
    <Router>
      {/* {language === 'en' ? <ENHeader toggleLanguage={toggleLanguage} /> : <FRHeader toggleLanguage={toggleLanguage} />} */}
      <Routes>
        <Route path="/en" element={<EnWelcome />} />
        <Route path="/en/portfolio" element={<Portfolio />} />
        <Route path="/fr" element={<FrWelcome />} />
        <Route path="/hack" element={<Hack />} />
        <Route path="/" element={<ErrorPage />} />
        <Route path="/en/contact" element={<EnContact />} />
        <Route path="/max" element={<Max />} />
        <Route path="/useless" element={<Useless />} />
        <Route path="/notekeeper" element={<MainNote />} />
        <Route path="/olympics" element={<OG />} />
        <Route path="/kasia" element={<Kasia />} />
        <Route path="/kasia/gallery" element={<Gallery />} />
        <Route path="/kasia/finalmessage" element={<FinalMessage />} />
        <Route path="*" element={<Hack />} />
      </Routes>
      {/* <EnFooter /> */}
    </Router>
  );
}

export default App;