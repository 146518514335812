export const memories = [
    {
      title: "Our First Date",
      description: "It was a magical evening where we got to know each other...",
      date: "February 12, 2024",
      image: "../images/kate.jpg"
    },
    {
      title: "Vacation in Poland",
      description: "Our vacation in Poland where you introduced me to your beautiful country.",
      date: "August 15, 2022",
      image: "../images/kate.jpg"
    },
    // Add as many memories as you want
  ];
  