import React from 'react';

function ScoreTable({ scores }) {
  return (
    <table>
      <thead>
        <tr>
          <th>Discipline</th>
          <th>Participant(s)</th>
          <th>Score</th>
        </tr>
      </thead>
      <tbody>
        {scores.map((score, index) => (
          <tr key={index}>
            <td>{score.discipline}</td>
            <td>{score.participants.join(', ')}</td>
            <td>{score.score}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ScoreTable;
