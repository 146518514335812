import React, { useEffect } from 'react';
import { memories } from '../data/memories';
import MemoryCard from '../MemoryCard';
import './style.css';

const Gallery = () => {
  return (
    <div className="gallery">
      {memories.map((memory, index) => (
        <MemoryCard key={index} memory={memory} />
      ))}
    </div>
  );
};

export default Gallery;
