import React, { useEffect } from 'react';

const MemoryCard = ({ memory }) => {
  return (
    <div className="memory-card">
      <img src={memory.image} alt={memory.title} className="memory-photo" />
      <h2>{memory.title}</h2>
      <p>{memory.description}</p>
      <p className="memory-date">{memory.date}</p>
    </div>
  );
};

export default MemoryCard;
