import React from 'react';
import ScoreTable from '../ScoreTable';

function DisciplinePage({ scores, discipline }) {
  return (
    <div>
      <h2>{discipline}</h2>
      <ScoreTable scores={scores} />
      <h3>Statistiques</h3>
      <p>Nombre de participants: {scores.length}</p>
      <p>Score moyen: {scores.length > 0 ? (scores.reduce((sum, score) => sum + parseInt(score.score), 0) / scores.length).toFixed(2) : 0}</p>
    </div>
  );
}

export default DisciplinePage;
