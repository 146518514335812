import React from 'react';

function History({ history }) {
  return (
    <div>
      <h2>Historique des Scores</h2>
      <ul>
        {history.map((entry, index) => (
          <li key={index}>
            {entry.participants.join(' et ')} ont marqué {entry.score} en {entry.discipline}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default History;
