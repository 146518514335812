import React from 'react';
import { Link } from 'react-router-dom';

function DisciplineMenu({ disciplines }) {
  return (
    <nav>
      <ul>
        {disciplines.map((discipline) => (
          <li key={discipline}>
            <Link to={`/${discipline.toLowerCase()}`}>{discipline}</Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default DisciplineMenu;
